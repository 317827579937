import React from 'react'
import Aboutsec from '../Menabout/Aboutsec'
import Aboutservice from '../Menabout/Aboutservice'
// import Aboutwhyus from '../Menabout/Aboutwhyus'
import Testimonialsabout from '../Menabout/Testimonialsabout'
// import Winningchef from '../Menabout/Winningchef'
import Bredcrumb from '../Bredcrumb/Main'
// import Img from '../../assets/images/background/banner-image-1.jpg'
import Bgtwo from '../../assets/images/background/contact-background.jpg'
import bookicon1 from '../../assets/images/resource/favicon.png'
import { Link } from 'react-router-dom'




function Main() {
  return (

    <>
      <Bredcrumb
        subtitle="About Us"
        title="OUR STORY"
        Img={Bgtwo}
      />

      <Aboutsec />
      {/* <Winningchef /> */}
      <Aboutservice />
      <Testimonialsabout />
      <div className="books-btn" 
        style={{position: 'fixed'}}
      >
        <Link to="/" className="theme-btn plausible-event-name=Menu-top-click">
          <span className="icon">
            <img src={bookicon1} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Menu" />
          </span>
          <span className="txt">Menu</span>
        </Link>
      </div>
      {/* <Aboutwhyus /> */}
    </>

  )
}

export default Main