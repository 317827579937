import React, { useEffect, useState } from "react";
// import { api } from "../../lib/axios";
import { PostCard } from "./PostCard";
import Bredcrumb from '../Bredcrumb/Main';
import Bgtwo from '../../assets/images/background/contact-background.jpg'
import { Link } from 'react-router-dom'
import bookicon1 from '../../assets/images/resource/favicon.png'
import {
    HomeContainer,
    HomeContent,
    ListSection,
    SearchSection,
} from "./styles";
import fuzzysort from 'fuzzysort'
import posts, { Signatures } from './data';

export function Blogs() {
    const [ filterdPost, setPosts ] = useState([...Signatures, ...posts]);

    useEffect(() => {
        document.title = 'Nectar Hideaway Taste the Adventure: Journeying through the World of Cocktail and Mocktail';
    }, []);





    const filterPost = (e) => {
        let objects = [...posts];
        const value = e.target.value;
        if (!value) {
            setPosts([...Signatures, ...objects])
            return
        }

        const signatures = fuzzysort.go(value, Signatures, {
            keys: ['title', 'taste', 'ingredients', 'description', 'body'],
        });

        const results = fuzzysort.go(value, objects, {
            keys: ['title', 'taste', 'ingredients', 'description', 'body'],
            // Create a custom combined score to sort by. -100 to the desc score makes it a worse match
            // scoreFn: a => Math.max(a[0] ? a[0].score : -1000, a[1] ? a[1].score - 100 : -1000)
        })
        setPosts([...signatures, ...results]
            // .filter(it => it.score > -100)
            .map(it => it.obj))
    }

    return (
        <>
            <Bredcrumb
                subtitle="Nectar Hideaway Blogs"
                title="OUR STORY"
                Img={Bgtwo}
            />

            <div className="auto-container">
                <HomeContainer>
                    <HomeContent>
                        <SearchSection>
                            <div>
                                <span>Posts</span>
                            </div>
                            <input
                                type="text"
                                // onBlur={(e) => fetchPosts(e.target.value)}
                                onChange={filterPost}
                                placeholder="Search by name, taste, base..."
                            />
                        </SearchSection>
                        <ListSection>
                            {filterdPost &&
                                filterdPost.map((post) => {
                                    return (
                                    <PostCard
                                        key={`${post?.title}-${post?.number}`}
                                        post={post}
                                    ></PostCard>
                                )
                                })}
                        </ListSection>
                    </HomeContent>
                </HomeContainer>
            </div>
            <div className="books-btn"
                style={{ position: 'fixed' }}
            >
                <Link to="/" className="theme-btn plausible-event-name=Menu-top-click">
                    <span className="icon">
                        <img src={bookicon1} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Menu" />
                    </span>
                    <span className="txt">Menu</span>
                </Link>
            </div>

        </>
    );
}