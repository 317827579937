import React  from 'react'
import { Link } from 'react-router-dom'
// import AnimatedNumber from "animated-number-react"
// import Iframe from 'react-iframe';
import logonew from '../../assets/images/resource/logo3.png'
// import bgten from '../../assets/images/background/bg-10.png'
// import bgeleven from '../../assets/images/background/bg-11.png'
// import signatureone from '../../assets/images/resource/signature-1.png'
// import aboutimgone from '../../assets/images/resource/about-image-2.jpg'
// import aboutimgtwo from '../../assets/images/resource/about-image-1.jpg'

import Bgtwo from '../../assets/images/background/bg-2.png'
import bgone from '../../assets/images/background/bg-1.png'

function Aboutsec() {

    // const [Video, setVideo] = useState()

    return (
        <>
            <section className="about-section">
                <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>who we are</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                        </div>

                        {/* <h1 style={{color: 'rgb(23,24,25)'}}>
                            Nectar Hideaway Speakeasy Rooftop Cocktails Bar
                        </h1> */}
                        <h1>
                            A hidden gem in the heart of the city, our speakeasy cocktails bar will captivate your senses.
                        </h1>
{/* 
                        <h3>A modern restaurant with a menu that will make your mouth water.
                            Servicing delicious food <span className="theme_color">since 45 years</span>. 
                            Enjoy our seasonal menu and experience the beauty of naturalness</h3> */}
                    </div>
                    <div className="row clearfix">

                        <div className="about-block content-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="info">
                                    <h3 style={{fontSize: '20px'}}>
                                        At Nectar Hideaway, we take pride in our handcrafted cocktails, skillfully prepared by our talented mixologists. <br /><br />
                                        From classic favorites to innovative signature drinks, each libation is a work of art, infused with the finest ingredients and attention to detail.
                                        As you relax on our rooftop, take in the breathtaking views of the Opera House and savor the tantalizing flavors of our beverages. <br /> <br />
                                        Whether you're seeking a romantic date night or a delightful evening with friends, our bar promises an unforgettable journey into the world of exquisite cocktails and timeless charm. <br/> <br/>
                                        Cheers to creating cherished memories at Nectar Hideaway!
                                    </h3>
                                </div>
                                
                                {/* <div className="signature"><img src={signatureone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}
                                <div className="author float-right">lee - founder</div>
                                {/* <div className="video-box">
                                    <div className="image"><Link to="https://www.youtube.com/watch?v=ZETY_l3GVQg" className="lightbox-image"><img src={aboutimgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></Link>
                                        <Link to="#" className="lightbox-image play-btn" onClick={() => setVideo(true)}>
                                            <span className="icon fal fa-play">
                                                <i className="ripple"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>


                        <div className="about-block image-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                {/* <div className="image"><img src={aboutimgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                <div className="logo"><Link to="/" title="Nectar Hideaway"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Nectar Hideaway" /></Link></div>
                            </div>
                        </div>


                        <div className="about-block info-block col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <ul className="info">
                                    <li><strong>Open Time</strong>Monday to Sunday <br />5.00 pm - 12.00pm</li>
                                    <li><strong>After Work</strong>Monday to Sunday <br />5.00 pm - 7.30pm</li>
                                    <li><strong>Happy Hours</strong>Monday to Sunday <br />7.30 pm - 9.00 pm</li>
                                    <li className="separator"><span></span></li>
                                    {/* <li><strong>Contact Us</strong><span className="address">Restaurant St, Delicious City, London 9578, UK</span> <Link to="tel:+88-123-123456">+88-123-123456</Link> <br /><Link to="mailto:reservation@restro.com">reservation@restro.com</Link></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>
                    {/* <div className="fact-counter">
                        <div className="row clearfix">
                            <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                                <div className="inner clearfix">
                                    <div className="fact-count"><div className="count-box">
                                        <AnimatedNumber className="count-text" value={150} duration={2000} formatValue={(v) => Math.round(v)} />
                                        <i>+</i></div></div>
                                    <div className="fact-title">daily order</div>
                                </div>
                            </div>
                            <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                                <div className="inner clearfix">
                                    <div className="fact-count"><div className="count-box">
                                        <AnimatedNumber className="count-text" value={82} duration={1500} formatValue={(v) => Math.round(v)} />
                                        <i>+</i></div></div>
                                    <div className="fact-title">Special Dishes</div>
                                </div>
                            </div>
                            <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                                <div className="inner clearfix">
                                    <div className="fact-count"><div className="count-box">
                                        <AnimatedNumber className="count-text" value={35} duration={1000} formatValue={(v) => Math.round(v)} />
                                        <i>+</i></div></div>
                                    <div className="fact-title">expert chef</div>
                                </div>
                            </div>
                            <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                                <div className="inner clearfix">
                                    <div className="fact-count"><div className="count-box">
                                        <AnimatedNumber className="count-text" value={10} duration={1000} formatValue={(v) => Math.round(v)} />
                                        <i>+</i></div></div>
                                    <div className="fact-title">awards won</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

        </>
    )
}

export default Aboutsec