import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const PostCardContainer = styled(NavLink)`
position: relative;
width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 2rem;

  background: ${props => props.theme['base-post']};
  border-radius: 10px;
  border: 2px solid rgb(129 101 53 / 70%);

  /* height: 260px; */
  overflow: hidden;

  transition: border 0.2s;

  cursor: pointer;
  header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    h1 {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 160%;
      color: ${props => props.theme['base-title']};
      text-align: justify;
    }
    
    span {
      font-size: 0.875rem;
      line-height: 160%;
      color: ${props => props.theme['base-span']};
      
    }
  }
  
  main {
    /* height: 112px; */
    overflow: hidden;
    p {
      /* height: 100%; */
      text-align: justify;
      color: ${props => props.theme['base-text']};
    }
  }

  &:hover {
    border: 2px solid ${props => props.theme['base-label']};
    color: unset;
  }

  .read-more {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
`;


export const FlipCardContainer = styled.div`
/* @import 'https://fonts.googleapis.com/css?family=Lily+Script+One'; */
/* body {
  background: #eee;
  font-family: 'Lily Script One';
} */

.flip-card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  margin: -150px;
  float: left;
  perspective: 500px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);

  transition: transform 1s;
  transform-style: preserve-3d;
}

.flip-card :hover .content {
  transform: rotateY( 180deg ) ;
  transition: transform 0.5s;
}

.front,
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  line-height: 300px;
  color: #03446A;
  text-align: center;
  font-size: 60px;
  border-radius: 5px;
  backface-visibility: hidden;
}

.back {
  background: #03446A;
  color: white;
  transform: rotateY( 180deg );
}
`