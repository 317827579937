import React from 'react'
// import Backgroundimage from '../../assets/images/background/image-7.jpg';
import OwlCarousel from 'react-owl-carousel';
import quotesone from '../../assets/images/icons/quotes-1.png'
// import authorthumb from '../../assets/images/resource/author-thumb-4.jpg'
// import authorthumbone from '../../assets/images/resource/author-thumb-5.jpg'
// import authorthumbtwo from '../../assets/images/resource/author-thumb-6.jpg'


function Testimonialsabout() {
    const show ={
        loop:true,
			margin:50,
			nav:true,
			smartSpeed: 700,
			autoplay: true,
			autoplayTimeout:7000,
			navText: [ '<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>' ],
			responsive:{
				0:{
					items:1
				},
				600:{
					items:1
				},
				768:{
					items:2,
					margin:30
				},
				992:{
					items:2,
					margin:30
				},
				1200:{
					items:3
				}
			}
    };

    return (
        <>
        
            <section className="testimonials-two">
                {/* <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div> */}
                <div className="auto-container">

                    <div className="title-box centered">
                        <div className="subtitle"><span>testimonials</span></div>
                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                        </div>
                        <h2>What People Are Saying</h2>
                    </div>

                    <div className="carousel-box" show={show} >

                        <OwlCarousel className="testimonial-slider owl-theme owl-carousel" loop margin={50} {...show}>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">暖和气氛，好音乐，好喝的鸡尾酒。河内中心喝酒的理想地方💯</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Nguyen Thao</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">I had an amazing experience at this Speakeasy Cocktails Bar! The drinks were absolutely delicious, and the variety of options was impressive. The spacious and elegant ambiance added to the overall enjoyment...</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Lê Đức Tiến</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Wow, this bar is amazing. The vibe is really cosy, like a secret little nest away from the city rush downstairs. You have a beautiful view and a tranquil nest to enjoy the city. The bartender puts a lot of love and care into his signature menu and I highly recommend those drinks above your usual</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Bridie Hill</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating">
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"> </span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                    </div>
                                    <div className="text">Amazing speakeasy cocktails bar! Phenomenal drinks, cool atmosphere. Fantastic happy hour deals!  A must-visit for cocktail enthusiasts.</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Simon Nguyen</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Found this hidden rooftop for cocktails. A fun experience. Worth the stairs. Happy hour was a bonus.</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Natalie Ackerman</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Good place to enjoy your evening time with friends  </div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Quỳnh Giao</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>




                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Absolutely loved the experience at this speakeasy cocktails bar! From the moment I stepped in, I was captivated by the stylish ambiance and the impressive selection of cocktails. The bartenders here are true mixology maestros, crafting delicious concoctions that left my taste buds dancing with joy.</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">tan nguyen duy</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">I went here in a rainy day. The environment here makes me feel so good. Nice place, friendly staffs, good cocktail tasting... <br/> This is absolutely my favorite bar in HN</div>
                                    <div className="auth-info">
                                        {/* <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div> */}
                                        <div className="auth-title">Khanh Linh Le</div>
                                        {/* <div className="location">New York</div> */}
                                    </div>
                                </div>
                            </div>


                            {/* <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Hygienic food & fresh enviroment, everyone had a wonderful delite experience. It was FABULOUS! great experience at The Italian gourmet.</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div>
                                        <div className="auth-title">Theresa Tin</div>
                                        <div className="location">Chicago</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Special treat to dine, It was wow experience food was really delicious! outstanding dinner made by Mater chef, food experience was unfogetable!</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div>
                                        <div className="auth-title">Michel Byrd</div>
                                        <div className="location">Denmark</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">The taste of food was really amazing, Wow ! outstanding dinner made by Mater chef John Rute, I never forget this delicious food experience.</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumb} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div>
                                        <div className="auth-title">willium joe</div>
                                        <div className="location">New York</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Hygienic food & fresh enviroment, everyone had a wonderful delite experience. It was FABULOUS! great experience at The Italian gourmet.</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div>
                                        <div className="auth-title">Theresa Tin</div>
                                        <div className="location">Chicago</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Special treat to dine, It was wow experience food was really delicious! outstanding dinner made by Mater chef, food experience was unfogetable!</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" /></div>
                                        <div className="auth-title">Michel Byrd</div>
                                        <div className="location">Denmark</div>
                                    </div>
                                </div>
                            </div> */}

                        </OwlCarousel>
                        
                    </div>

                </div>
            </section>

        </>
    )
}

export default Testimonialsabout