import React from "react";
import { PostCardContainer } from "./styles";

const formatText = (text, limitLength = 50) => {
  const textArr = text.split(" ")
  const newText = textArr.map((string, index) => {
    if (index < limitLength) return string
    return null;
  }).filter(string => string !== undefined
  )
  return `${newText.toString().replaceAll(",", " ")}...`
}

export function PostCard({ post }) {
  const { description, title, number, taste, ingredients } = post;
  return (
    <PostCardContainer to={`/blogs/${number}`}>
      <div>
        <header>
          <h1>{title}</h1>
        </header>
      </div>
      <main>
        {taste && <p style={{ color: 'rgb(171 143 96)', fontStyle: 'italic' }}>Taste: {taste}</p>}
        {
          ingredients && <p style={{ color: 'rgb(171 143 96)', fontStyle: 'italic' }}>Ingredients: {ingredients}</p>
        }
        <p>{formatText(description, 80)}</p>
        <span className="read-more">More Detail</span>
      </main>
    </PostCardContainer>
  );
}