import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';



// import OfferImmage from '../../assets/images/resource/offer.jpg'
// import OfferImmage2 from '../../assets/images/resource/offer2.jpg'
import OfferImmage7 from '../../assets/images/resource/offer7.jpg'
import OfferImmage8 from '../../assets/images/resource/offer8.jpg'
import OfferImmage9 from '../../assets/images/resource/offer9.jpg'
import bookicon1 from '../../assets/images/resource/favicon.png'


const scroll = () => {
    const section = document.querySelector('#menu-section');
    window.gtag('event', 'conversion', {'send_to': 'AW-11157048854/r9oBCImnxd8YEJacjMgp'});
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

function Banner() {
    return (
        <>

            <Swiper className="banner-section"
                loop={true}
                spaceBetween={50}
                slidesPerView={1}
                effect={'fade'}
                autoplay={{
                    delay: 5500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                }}>

                <div style={{maxHeight: '50'}} className="banner-container">
                    <div className="banner-slider" >
                        <div className="swiper-wrapper">

                            {/* <SwiperSlide>
                                <div className="swiper-slide slide-item">

                                    <div className="image-layer" style={{ backgroundImage: `url(${OfferImmage})` }}></div>

                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="">
                                                    <div className="">
                                                        <div className="subtitle"><span>delightful experience</span></div>

                                                        <div className="pattern-image">

                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />

                                                        </div>


                                                        <h1><span>Flavors Inspired by <br />the Seasons</span></h1>
                                                        <div className="text">Come with family & feel the joy of mouthwatering food</div>
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide> */}

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${OfferImmage7})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                                                        </div>
                                                        <h2><span style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>Where every flavor <br />tells a story</span></h2>
                                                        <div className="text" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>Unwind and Save: 30% Off Drinks Everyday! <br /> Join Our After Work & Happy Hours</div>
                                                        {/* <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${OfferImmage9})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>
                                                            <h2>
                                                                After Work
                                                            </h2>
                                                        </div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                                                        </div>
                                                        <span style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>30% discount on our Wine, Mix Drinks</span>
                                                        <div className="text" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>from 5:30 pm to 7:30 pm</div>
                                                        {/* <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${OfferImmage8})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>
                                                            <h2>
                                                                Happy Hours
                                                            </h2>
                                                        </div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                                                        </div>
                                                        <span className="subtitle" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>
                                                            30% discount Cocktails, Mix drinks
                                                        </span>
                                                        <div className="text" style={{ backdropFilter: 'blur(2px) saturate(70%)' }}>
                                                            from 7:30 pm to 9:00 pm
                                                        </div>
                                                        {/* <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>



                        </div>

                        {/* <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                        <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>
        */}

                    </div>
                </div>

                <div onClick={scroll} className="book-btn">
                    <Link to="#" className="theme-btn plausible-event-name=Menu-top-click">
                        <span className="icon">
                            <img src={bookicon1} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" />
                        </span>
                        <span className="txt">Menu</span>
                    </Link>
                </div>

            </Swiper>

        </>
    )
}

export default Banner