import React  from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/bg-2.png'
import bgone from '../../assets/images/background/bg-1.png'
import five from '../../assets/images/resource/cocktail.png'

const CLASSICS = [
    {
        name: 'Gin Tonic',
        price: '140.000',
        description: 'House Gin Tonic',
    },
    {
        name: 'Mizuwari	',
        price: '120.000',
        description: 'House Irish whisky Mineral water',
    },
    {
        name: 'Whisky Highball	',
        price: '140.000',
        description: 'House Blended Whisky Soda',
    },
    {
        name: 'Rum Coke	',
        price: '140.000',
        description: 'House Rum Coke',
    },
    {
        name: 'Jagger Bomb',
        price: '140.000',
        description: 'Jaggermeister, Redbull',
    },
    {
        name: 'Choya Tonic',
        price: '150.000',
        description: 'Choya blend Soda',
    },
]

function MixDrinks() {
    const filterdMenu = CLASSICS

    return (
        <>
            <section className="menu-section">

                <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        {/* <div className="subtitle"><span>Special selection</span></div> */}

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                        </div>
                        <h2>Mix Drinks</h2>
                    </div>

                    <div className="tabs-box menu-tabs">
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index %2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" key={item.name}>
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}` }/></Link></div>
                                                            <div className="title clearfix">
                                                                <div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div>
                                                                <div className="price"><span>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index %2 === 0) return null;
                                                return (
                                                    <div className="dish-block" key={item.name}>
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div>
                                                            <div className="title clearfix">
                                                                <div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div>
                                                                <div className="price"><span>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default MixDrinks